function createHeaderPlaceholder() {
  const header = document.querySelector('.at-header')
  const placeholderNode = document.createElement('div')
  placeholderNode.classList.add('at-placeholder')
  placeholderNode.style.width = `100%`
  placeholderNode.style.height = `${header.offsetHeight}px`
  header.parentElement.insertBefore(placeholderNode, header)

  new ResizeObserver(() => {
    if(document.querySelector('.at-header.active')) return
    placeholderNode.style.height = `${header.offsetHeight}px`
  }).observe(header)
}

function toggleFixedHeaderVisibility(entries) {
  if(!entries || !entries[0]) return
  const header = document.querySelector('.at-header')
  const classAction = entries[0].isIntersecting ? 'remove' : 'add'
  header.classList[classAction]('active')
}

function attachFixedHeader() {
  const headerGroup = document.querySelector('.at-archive__header-group')
  const headerPlaceholder = document.querySelector('.at-placeholder')
  const options = { rootMargin: '0px', threshold: 0 }
  const observer = new IntersectionObserver(toggleFixedHeaderVisibility, options)
  observer.observe(headerGroup || headerPlaceholder)
}

document.addEventListener('DOMContentLoaded', () => {
  const mqList = window.matchMedia('(min-width: 1024px)')
  createHeaderPlaceholder()
  if(mqList.matches) return attachFixedHeader()
})